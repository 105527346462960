<template>
    <div>
        <div class="F-h5 hidden-xs">
            <div class="F-column">
                <div class="F-name">{{ acticveFirstMenuInfo.name }}</div>
                <div class="F-btn fr">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </div>
            </div>
            <ul class="F-list-datas">
                <template v-for="(item, index) in acticveFirstMenuInfo.children">
                    <li :key="index" @click="handleMenu(item, index)" :class="active === item.id ? 'F-backs' : ''">
                        <a href="javascript:;"><span>{{ item.name }}</span><img class="fr hidden-xs" src="@/assets/images/right-gy.png" alt=""></a>
                    </li>
                </template>
            </ul>
        </div>

        <div class="F-h5 visible-xs">
            <div class="F-column" @click="isShow=!isShow">
                <div class="F-name">{{ acticveFirstMenuInfo.name }}</div>
                <div class="F-btn fr" style="padding: 0 10px">
                    <!-- <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span> -->
                    <span class="iconfont">&#xe62d;</span>
                    
                </div>
            </div>
            <collapse-transition>
                <ul class="F-list-datas" v-if="isShow">
                    <template v-for="(item, index) in acticveFirstMenuInfo.children">
                        <li :key="index" @click="handleMenu(item, index)" :class="active === item.id ? 'F-backs' : ''">
                            <a href="javascript:;"><span>{{ item.name }}</span><img class="fr hidden-xs" src="@/assets/images/right-gy.png" alt=""></a>
                        </li>
                    </template>
                </ul>
            </collapse-transition>
        </div>

    </div>
</template>

<script>
import CollapseTransition from '@/utils/collapse-transition';
export default {
    name: 'detail',
    data() {
        return {
            active: undefined,
            isShow: false
        }
    },
    props: [
        'acticveFirstMenuInfo', 'navLeftActive'
    ],
    watch: {
        navLeftActive(val) {
            this.active = val;
        }
    },
    components: {
        CollapseTransition
    },
    created() {
        this.active = this.navLeftActive;
    },
    methods: {
        handleMenu(val, index) {
            if(val.children && val.children.length > 0) {
                // 有三级菜单
                this.$router.push({ path: val.children[0].url })
            }else {
                // 有二级菜单
                this.$router.push({ path: val.url })
            }
        },
        handleName() {

        }
    },
}
</script>
