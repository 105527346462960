import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuList: [],
  },
  mutations: {
    setMenuList(state, menuList){
      state.menuList = menuList
    },
  },
  actions: {
  },
  modules: {
  }
})
