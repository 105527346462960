<template>
    <div id="app">
        <!-- 顶部 -->
        <nav class="navbar navbar-fixed-top navbar-default">
            <div class="container-fluid">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"
                            aria-expanded="false">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <h1 id="logo"><a href="javascript:;">海德双语学校</a></h1>
                    <h2 id="logo_suangyu">东莞市海德双语学校</h2>
                    <a class="navbar-brand" href="javascript:;">
                        <img id="logo-img" src="../assets/images/logo.png" alt="海德双语学校">
                    </a>
                </div>
                <ul class="F-xs visible-xs">
                    <li class="F-enZn">
                        <router-link to="/">
                            <span>中文</span>
                        </router-link>
                    </li>
                </ul>
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="F-top hidden-xs">
                        <li class="F-enZn">
                            <router-link to="/">
                                <span>中文</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="page-90">
                                <img src="../assets/images/form.png" alt=""><span>入学意向申请表</span>
                            </router-link>
                        </li>
                    </ul>
                    <nav-top :menuList="menuList"></nav-top>
                </div>
            </div>
        </nav>

        <router-view v-if="$route.path==='/'"></router-view>

        <main v-if="$route.path!=='/'">
            <div class="F-banner">
                <img src="@/assets/images/banner-gy.png" alt="">
            </div>
            <div class="F-page">
                <div class="F-main">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-3 col-xs-12 F-bor">
                                <!-- 左侧二级菜单 -->
                                <nav-left :acticveFirstMenuInfo="acticveFirstMenuInfo" :navLeftActive="navLeftActive" v-if="$route.path!=='/'"></nav-left>
                            </div>
                            <div class="col-sm-9 col-xs-12">
                                <!-- <nav-three :acticveSecMenuInfo="acticveSecMenuInfo" v-if="acticveSecMenuInfo.children && acticveSecMenuInfo.children.length > 0"></nav-three> -->
                                <div class="F-tab" style="text-align:left" v-if="acticveSecMenuInfo.component!=='cms/multiplePage' && acticveSecMenuInfo.children && acticveSecMenuInfo.children.length===0">
                                    {{ title }}
                                </div>
                                <router-view></router-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        
        
        <footer>
            <div class="F-footer">
                <p class="F-logo"><img src="../assets/images/logo-2.png" alt=""></p>
                <p class="F-FUTURE">EVERYTHING IS FOR THE FUTURE</p>
                <p class="F-weil">一切为了未来</p>
                <p class="F-World">From Dongguan China To The World</p>
                <p class="F-understand">懂东莞 懂中国 懂世界</p>
                <p class="F-image">
                    <img src="../assets/images/haidLOGOwhite.png" alt="">
                    <img class="F-MiddletonLOGO" src="../assets/images/MiddletonLOGO.png" alt="">
                    <img src="../assets/images/education.png" alt="">
                </p>
                <p class="F-text">
                    版权所有 ©东莞市海德双语学校 <br/>
                    地址：东莞市清溪镇清泉南路东莞海德双语学校 <br/>
                    <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备20054919号</a> <br/>
                </p>
            </div>
        </footer>
    </div>
</template>

<script>
import { listMenu } from '@/api/index'
import navTop from '@/components/nav-top'
import navLeft from '@/components/nav-left'
import navThree from '@/components/nav-three'

export default {
    data() {
        return {
            // 动态菜单列表
            menuList: [],
            // 选中的一级菜单信息
            acticveFirstMenuInfo: [],
            // 选中的二级菜单信息
            acticveSecMenuInfo: [],
            // 选中的一级菜单id
            navLeftActive: undefined,
            title: undefined
        }
    },
    components: {
        navTop, navLeft, navThree
    },
    watch: {
        '$store.state': {
            handler: function(state) {
            },
            deep: true
        },
        '$route':function(val) {
            this.getnavLeftActive();
        }
    },
    created() {
console.log(process.env.VUE_APP_BASE_API)

        if(this.$store.state.menuList.length===0) {
            listMenu().then(res => {
                this.menuList = res.data;
                this.$store.commit('setMenuList', this.menuList)
                this.getnavLeftActive();
            });
        }
    },
    methods: {
        getnavLeftActive() {
            if(this.$route.meta.id) {
                this.navLeftActive = this.$route.meta.level === 2 ? this.$route.meta.id : this.$route.meta.parentId;
                this.acticveFirstMenuInfo =  this.$store.state.menuList.filter(item => item.id === this.$route.meta.firstId)[0];
                if(this.acticveFirstMenuInfo && this.acticveFirstMenuInfo.children.length > 0) {
                    this.acticveSecMenuInfo = this.acticveFirstMenuInfo.children.filter(item => item.id === this.navLeftActive)[0];
                    this.title = this.acticveSecMenuInfo.name;
                }
            }else if(!this.$route.meta.id && this.$route.path !== '/') {
                this.navLeftActive = this.$route.query.meta.level === 2 ? this.$route.query.meta.id : this.$route.query.meta.parentId;
                this.acticveFirstMenuInfo =  this.$store.state.menuList.filter(item => item.id === this.$route.query.meta.firstId)[0];
                if(this.acticveFirstMenuInfo && this.acticveFirstMenuInfo.children.length > 0) {
                    this.acticveSecMenuInfo = this.acticveFirstMenuInfo.children.filter(item => item.id === this.navLeftActive)[0];
                    this.title = this.$route.query.title;
                }
            }
            
        }
    },
}
</script>

<style lang="less">

.F-footer {
    p {
        margin: 0 0 10px;
    }
}
</style>
