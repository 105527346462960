import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import { permission } from '@/permission.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: '首页',
        component: (resolve) =>  require(['@/views/home'], resolve)
      },
      
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
    {path: '/detail',
    name: '详情',
    component: (resolve) =>  require(['@/views/cms/detail'], resolve)}
    ]
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(router.options.routes[0].children.length > 1) {
    next()
  }else {
    permission()
    next()
  }
})


export default router
