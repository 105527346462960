import axios from 'axios'
import { Notification, Message } from 'element-ui'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/prod-api/',
  // baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  // timeout: 30000
})


// request拦截器
service.interceptors.request.use(config => {
  return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg
    if (code === 200) {
      return res.data;
    } else {
      Notification.error({
        title: msg
      })
      return Promise.reject('error')
    }
  },
  error => {
    let { message } = error;
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)


 

export default service
