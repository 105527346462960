<template>
    <div class="F-tab">
        <template v-for="(item, index) in acticveSecMenuInfo.children">
            <a :key="index" :class="active === item.id ? 'F-tab-list fl F-aver' : 'F-tab-list fl'" href="javascript:;" @click="changeTab(item)">{{ item.name }}</a>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: [
        'acticveSecMenuInfo'
    ],
    created() {
        this.active = this.acticveSecMenuInfo.children[0].id;
    },
    methods: {
        // 切换三级菜单
        changeTab(val) {
            this.$router.push({ path: val.url })
        }
    },
}
</script>