import router from './router'
import { listMenu } from '@/api/index'

export function permission() {
  listMenu().then(res => {
    let routes = JSON.parse(JSON.stringify(res.data));
    routes.forEach(item => {
        item.path = '/' + item.url;
        item.children.forEach(subItem => {
            subItem.path = '/' + subItem.url;
            subItem.children.forEach(threeItem => {
                threeItem.path = '/' + threeItem.url;
            })
        })
    })
    let arr = changeRouter(routes);
    router.options.routes[0].children = router.options.routes[0].children.concat(arr)
    router.addRoutes(router.options.routes)
  });
}
  
     
export const changeRouter = (routes) => {
  let arr = [];
  routes.forEach(item => {
      item.children.forEach(subItem => {
          arr.push({ path: subItem.path, name: subItem.name, component: (resolve) =>  require(['@/views/' + subItem.component], resolve), meta: { level: 2, firstId: subItem.parentId, id: subItem.id, parentId: subItem.parentId, url: subItem.url } })
          if(subItem.children && subItem.children.length > 0) {
            subItem.children.forEach(threeItem => {
              arr.push({ path: threeItem.path, name: threeItem.name, component: (resolve) =>  require(['@/views/' + threeItem.component], resolve), meta: { level: 3, firstId: subItem.parentId, thirdId:threeItem.id, id: threeItem.id, parentId: subItem.parentId, url: threeItem.url} })
            })
          }
      })
  })
  return arr
}



