import request from '@/utils/request'

// 查询动态路由菜单
export function listMenu(data) {
  return request({
    url: 'router?parentId=2',
    method: 'post',
    data: data
  })
}

// 查询page数据
export function getPage(url, data) {
  return request({
    url: 'cms/' + url,
    method: 'get',
    params: data
  })
}

// 查询首页广告
export function getAds(key, data) {
  return request({
    url: 'ads/'+ key,
    method: 'get',
    params: data
  })
}



