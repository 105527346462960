import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'bootstrap'

import 'bootstrap/dist/js/bootstrap.min.js'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/styles/nav.css'
import '@/assets/styles/initialize.css'
import '@/assets/styles/theColumn.css'
import '@/assets/styles/pagination.css'
import '@/assets/styles/news.css'
import '@/components/jquery.skidder/jquery.skidder.css'
import '@/assets/font/iconfont.css'

import { formatDate } from "@/utils/index";


router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

// 全局方法挂载
Vue.prototype.formatDate = formatDate


Vue.config.productionTip = false;

Vue.use(ElementUI);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
