<template>
    <ul class="nav navbar-nav">
        <li class="F-list">
            <router-link to="/">首页</router-link>
        </li>
        <!-- pc端 -->
        <li class="F-list hidden-xs" v-for="(item, index) in menuList" :key="item.id + 'hidden'" @mouseenter="enters(index)" @mouseleave="leaver(index)" @click="handleMenu(1, item, 'pc')">
            <a href="javascript:;" id="navBtn">{{ item.name }} <img class="F-an-ico visible-xs" :src="active===index && item.children.length > 0 ? require('@/assets/images/an.png') : require('@/assets/images/pack-up.png')" alt=""></a>
            <collapse-transition>
                <div class="F-drop-down" v-show="active===index && item.children.length > 0">
                    <ul>
                        <li class="F-list-child" v-for="(subItem, subIndex) in item.children" :key="subItem.id" @mouseenter="entersChild(subIndex)" @mouseleave="leaverChild(subIndex)" @click.stop="handleMenu(2, subItem, 'pc')">
                            <a href="javascript:;" id="navBtn">{{ subItem.name }}</a>
                        </li>
                    </ul>
                </div>
            </collapse-transition>
        </li>

        <!-- 手机端 -->
        <!-- <li class="F-list visible-xs" v-for="(item, index) in menuList" :key="item.id + 'visible'" @click="handleMenu(1, item, 'mobile')"> -->
        <li class="F-list visible-xs" v-for="(item, index) in menuList" :key="item.id + 'visible'" @click="handleMenu(1, item, 'mobile')">
            <a href="javascript:;" id="navBtn" @click="handleMobileMenu(index)">{{ item.name }} <img class="F-an-ico visible-xs" :src="active===index && item.children.length > 0 ? require('@/assets/images/an.png') : require('@/assets/images/pack-up.png')" alt=""></a>
            <collapse-transition>
                <div class="F-drop-down" v-show="active===index && item.children.length > 0">
                    <ul>
                        <li class="F-list-child" v-for="(subItem, subIndex) in item.children" :key="subItem.id" @mouseenter="entersChild(subIndex)" @mouseleave="leaverChild(subIndex)" @click.stop="handleMenu(2, subItem, 'mobile')">
                            <a href="javascript:;" id="navBtn">{{ subItem.name }}</a>
                        </li>
                    </ul>
                </div>
            </collapse-transition>
        </li>
        <li class="F-list visible-xs">
            <router-link to="page-90"><a href="javascript:;">入学意向申请表</a></router-link>
        </li>
        
    </ul>
</template>

<script>
import CollapseTransition from '@/utils/collapse-transition';

export default {
    data() {
        return {
            active: -1,
            activeChild: -1
        }
    },
    props: {
        menuList: {
            type: Array
        }
    },
    components: {
        CollapseTransition
    },
    created() {
        
    },
    methods: {
        // 一级菜单移入
        enters(index) {
            this.active = index;
        },
        // 一级菜单移出
        leaver(index) {
            if(this.activeChild === -1) {
                this.active = -1;
            }
        },
        // 二级菜单移入
        entersChild(index) {
            this.activeChild = index;
        },
        // 二级菜单移出
        leaverChild(index) {
            this.activeChild = -1;
        },
        // 点击菜单跳转页面
        handleMenu(index, val, type) {
            let path = '';
            if(index === 1) {
                path = val.children[0].url;
            }else {
                path = val.url;
            }
            if(type === 'pc' || (type === 'mobile' && index === 2)) {
                this.$router.push({ path: path })
            }
        },
        // 点击手机端一级菜单收缩
        handleMobileMenu(index) {
            if(this.active === index) {
                this.active = -1;
            }else {
                this.active = index;
            };
        }
    },
}
</script>